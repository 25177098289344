@use "../abstracts" as *;
@import "../../variables";

%textStyle {
    font-family: $font-main;
    font-size: size(text, 300);
    font-weight: 600;
    font-style: normal;
    text-align: center;
    color: color(grayScale, 600);
    padding: 4px 18px;

    @include for-phone-and-tablet {
        color: color(white, 100);
        font-size: size(text, 400);
        font-weight: 700;
        text-align: center;

        &:hover {
            color: color(white, 100);
        }
    }

    @include for-tablet-landscape-up {
        &:hover {
            background: color(secondary, 200);
            color: color(grayScale, 600);
            border-radius: 13px;
        }
    }
}

.nav {
    @extend %transitionDefault;
    width: 100%;
    justify-content: center;


    &__list {
        @extend %transitionDefault;
        @include for-desktop-up {
            @include flex(flex, row, center, space-evenly);
        }

        @include for-phone-and-tablet {
            @include flex(flex, column, flex-start, flex-start);
            padding-left: 2rem;

            .nav__item {
                padding: 8px 0;
            }
        }
    }

    &__link {
        @extend %textStyle;
    }

    &__item {
        @include for-phone-and-tablet {
            i {
                color: color(white, 100);
            }
        }
    }
}

.nav-mobile {
    @extend %transitionDefault;

    position: fixed;
    top: 0;
    right: -240px;
    z-index: 100;
    width: 240px;
    height: 100%;
    background-color: color(primary, 200);

    &__overlay {
        @extend %transitionDefault;

        position: fixed;
        top: 0;
        right: -100%;
        z-index: 99;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.3);
    }

    &__close {
        text-align: right;
        font-size: size(text, 400);
        margin: 2rem 1rem 0 0;
        color: #fff;

        &:hover {
            cursor: pointer;
        }
    }

    &__button {
        color: color(primary, 300);
        width: 100%;
        font-size: size(text, 500);
        cursor: pointer;
    }

    @include for-desktop-up {
        position: static;
        width: 64%;
        background-color: transparent;

        &__button,
        &__close {
            display: none;
        }
    }

    &__active,
    &__active>&__overlay {
        right: 0;
    }
}
