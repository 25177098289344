// Body
$body-bg: #ededed;

// Typography
$font-1: "Lato", sans-serif;

// Colors
$blue: #27384A;
$white: #FFFFFF;
$orange: #F26521;
$blue-light: #4296ca;
$blue-lighter: #4296ca;
$gray: #6A6A6A;
$gray-lighter: #AAAAAA;
$gray-darker: #595959;

$media: (
  'tv': 2000px,
  'desktop': 992px,
  'beforeDesktop': 991px,
  'tablet': 768px,
  'beforeTablet': 767px,
  'mobile': 600px,
  'customContainer': 1200px,
);
