@use "../abstracts" as *;
@import "../../variables";

.information {
  background-color: #363839;

  &__title {
    font-family: "Lato", sans-serif;
    font-weight: 500;
    font-size: size(text, 500);
    text-transform: uppercase;
    color: color(white, 100);
    margin-bottom: 0.5rem;

  }

  &__item {
    color: color(white, 100);

    &-city-menu {
      color: color(primary, 300);
      font-weight: 600;
      text-transform: uppercase;

      .contact-menu__link {
        color: color(primary, 300);
        font-weight: 600;
        text-transform: uppercase;
      }
    }

    &-city {
      color: white;
      font-weight: 600;
      text-transform: uppercase;
    }

    &:not(:last-of-type) {
      margin-bottom: 0.5rem;
    }

    @include for-phone-only {
      justify-content: center;
    }

    // a {
    //   &:hover {
    //     color: color(secondary, 300);
    //   }
    // }
  }

  &__link {
    color: color(white, 100);
    font-size: size(text, 300);

    &-phone {
      color: color(primary, 300);
    }

    &:hover {
      color: color(secondary, 300);
    }
  }

  &__icon {
    color: color(white, 100);
    font-size: size(text);
    margin-right: 1rem;

    &:hover {
      color: color(secondary, 300);
    }
  }

  &__column {
    margin-bottom: 1rem;

    @include for-desktop-up {
      margin-bottom: unset;

      &:not(:last-of-type) {
        border-right: 2px solid white;
      }

      &:nth-child(2) {
        padding-left: 5rem;
      }
    }
  }

  @media (max-width: 1200px) {
    background-image: unset !important;
  }
}

.rights {
  &__text {
    &-w3 {
      @include for-phone-only {
        margin: auto;
      }
    }
  }

  align-items: center;
  min-height: 40px;
  padding: 0.5rem 0px;
  background-color: color(primary, 500);

  & p {
    color: color(white, 100);

    @include for-phone-only {
      text-align: center;
    }
  }

  @include for-desktop-up {
    flex-direction: row;
  }

  @include for-phone-only {
    flex-direction: column;
    text-align: center;
  }
}