@use "../abstracts" as *;

.banner-pages {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 230px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  &__figure {
    margin-bottom: 8px;
  }

  &__image {
    width: 0px;
  }

  &__title {
    font: 500 36px/32px "Lato", sans-serif;
    color: color(grayScale, 600);
    text-align: center;

    @include for-phone-only {
      font-size: 28px;
    }

    &::after {
      content: "";
      display: block;
      height: 4px;
      margin: 0.75rem auto;
      background-color: color(secondary, 300);
      border-radius: 4px;
    }
  }

  &__model {
    font: 400 40px/48px "Lato", sans-serif;
    color: #fff;
    text-align: center;

    @include for-phone-only {
      font-size: 40px;
    }
  }
}