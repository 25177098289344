@use "../abstracts" as *;

.floating {
  display: none;

  @media (min-width: 992px) {
    position: fixed;
    right: 1em;
    bottom: -428px;
    z-index: 100;
    opacity: 0.5;
    display: block;
    width: 280px;
    min-height: 465px;
    padding: 16px 8px;
    padding-top: unset;
    background-color: white;
    border: 2px solid #eeeeee;
    box-shadow: 4px 3px 3px rgba(0, 0, 0, 0.16);
    border-radius: 7px;
    transition: all 0.5s ease-in-out;

    &__close {
      padding: 8px;
      text-align: right;

      i {
        font-size: 16px;
        color: #4296ca;
        cursor: pointer;
      }
    }

    &__image {
      width: 194px;
    }

    &__title {
      color: #707070;
      font-size: 31px;
      font-weight: 900;
      line-height: 37, 2px;

      &::after {
        content: '';
        display: block;
        width: 156px;
        height: 2px;
        margin: 20px auto 0 auto;
        background-color: #406381;
      }
    }

    &__text {
      font-size: 14px;
      color: #707070;
    }

    &__field {
      background-color: #eee;
      min-height: 36px;
      border-radius: 4px;
      padding-left: 30px;

      &::placeholder {
        color: #999;
        font-size: 12px;
      }
    }

    &__group {
      position: relative;

      i {
        position: absolute;
        top: 15%;
        left: 5px;
        z-index: 10;
        color: #222;
        transform: translateY(50%);
      }

      &:last-of-type {
        i {
          left: 9px;
        }
      }
    }

    &__button {
      width: 100% !important;
      background-color: #FF5528 !important;
      font-size: 18px !important;
      font-weight: 900;
      border: 0;
      border-radius: 4px;
    }

    &--open {
      bottom: 0;
      z-index: 1003;
      opacity: 1;
    }
  }
}
