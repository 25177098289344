@use "../abstracts" as *;

.btn-primary {
  @include button(primary, size(text), white, 109px, 41px);
  @extend %buttonDefault;

  &:hover,
  &:focus,
  &:active {
    color: color(primary) !important;
    background-color: white !important;
    border: 1px solid color(primary) !important;
  }
}

.btn-secondary {
  @extend %buttonDefault;
  width: 147px;
  min-height: 41px;
  font-size: size(text, 200);
  color: color(white, 100);
  background-color: color(primary, 100);
  border-color: transparent;

  &:hover,
  &:focus,
  &:active,
  &:focus {
    color: white !important;
    background-color: color(primary, 800) !important;
    border-color: transparent;
  }
}

.btn-tertiary {
  @extend %buttonDefault;
  width: auto;
  min-height: 41px;
  font-weight: 700;
  font-size: size(text, 300);
  color: color(white, 100);
  background-color: color(primary, 300);
  border-color: transparent;

  &:hover,
  &:focus,
  &:active,
  &:focus {
    color: #000;
    background-color: color(secondary, 300);
    border-color: transparent;
  }
}

.btn-clean {
  @extend %transitionDefault;
  background-color: color(primary, 300);
  color: #FFFFFF;
  font-size: 1rem;
  font-weight: 700;

  &:hover,
  &:focus,
  &:active,
  &:focus {
    color: color(white, 100);
    background-color: color(primary, 400);
  }
}

.btn-submit {
  @extend %transitionDefault;
  background-color: color(secondary, 300);
  color: #FFFFFF;
  font-size: 1rem;
  font-weight: 700;

  &:hover,
  &:focus,
  &:active,
  &:focus {
    color: color(white, 100);
    background-color: color(secondary, 100);
  }
}
