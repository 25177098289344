@use "../abstracts" as *;
@import "../../variables";

.header {
    width: 100%;
    background-color: $white;

    .top {
        background-color: color(primary, 300);
        box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.15);
        padding: 18px 0;

        @include for-phone-only {
            font-size: size(text, 200);
        }

        .list {
            gap: 12px;

            .list-social {
                display: flex;
                align-items: center;

                li {
                    color: color(white, 100);
                    font-weight: bold;
                }

            }

            i {
                font-size: 20px;
                color: color(white, 100);
            }
        }
    }

    &__section {
        padding: 20px 0;
        background: rgba(217, 217, 217, 0.13);
    }
}
